var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn-toggle',{attrs:{"rounded":""}},[_c('v-btn',{attrs:{"small":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-play-circle")]),_vm._v("Iniciar ")],1),_c('v-btn',{attrs:{"small":"","color":"yellow darken-1"},on:{"click":function($event){return _vm.restaurar()}}},[_c('v-icon',[_vm._v("mdi-restart")]),_vm._v("Restaurar ")],1),_c('v-btn',{attrs:{"small":"","color":_vm.ver_respuestas?'green lighten-1':''},on:{"click":function($event){_vm.ver_respuestas = !_vm.ver_respuestas}}},[_c('v-icon',[_vm._v(_vm._s(_vm.ver_respuestas?'mdi-eye-settings':'mdi-eye-off'))]),_vm._v("Ver resp. ")],1)],1)],1),_c('v-col',{staticClass:"px-3",attrs:{"cols":"12"}},[(_vm.preguntas.length != 0)?_c('v-card',[_c('v-card-title',{attrs:{"align":"end"}},[_c('v-spacer'),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","md":"2"}},[_c('v-alert',{attrs:{"dense":"","shaped":"","outlined":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.puntaje+'/200')+" ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","md":"2"}},[_c('v-alert',{attrs:{"dense":"","shaped":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.puntaje_error+'/200')+" ")])],1)],1),_c('v-card-text',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s((_vm.pagina) +"._"+ _vm.preguntas[_vm.pagina-1].pregunta_descripcion)+" "),(_vm.preguntas[_vm.pagina-1].pregunta_img)?_c('v-img',{attrs:{"height":"150","contain":"","src":'img_preguntas/'+(_vm.pagina)+'.jpg'}}):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',{class:[
                    (_vm.preguntas[_vm.pagina-1].pregunta_clave == _vm.preguntas[_vm.pagina-1].pregunta_respuesta1[0] && _vm.ver_respuestas)||_vm.preguntas[_vm.pagina-1].correcto == _vm.preguntas[_vm.pagina-1].pregunta_respuesta1[0]?'my-1 rounded-pill green lighten-1':'my-1 rounded-pill',
                    _vm.preguntas[_vm.pagina-1].marcado == _vm.preguntas[_vm.pagina-1].pregunta_respuesta1[0]?'my-1 rounded-pill '+_vm.preguntas[_vm.pagina-1].marcado_color:'my-1 rounded-pill '],attrs:{"cols":"12","disabled":"","rounded":""},on:{"click":function($event){_vm.preguntas[_vm.pagina-1].marcado?'':_vm.verificar_respuesta(_vm.pagina-1,'A')}}},[_vm._v(_vm._s(_vm.preguntas[_vm.pagina-1].pregunta_respuesta1))]),_c('v-col',{class:[
                    (_vm.preguntas[_vm.pagina-1].pregunta_clave == _vm.preguntas[_vm.pagina-1].pregunta_respuesta2[0] && _vm.ver_respuestas)||_vm.preguntas[_vm.pagina-1].correcto == _vm.preguntas[_vm.pagina-1].pregunta_respuesta2[0]?'my-1 rounded-pill green lighten-1':'my-1 rounded-pill',
                    _vm.preguntas[_vm.pagina-1].marcado == _vm.preguntas[_vm.pagina-1].pregunta_respuesta2[0]?'my-1 rounded-pill '+_vm.preguntas[_vm.pagina-1].marcado_color:'my-1 rounded-pill '],attrs:{"cols":"12","disabled":"","rounded":""},on:{"click":function($event){_vm.preguntas[_vm.pagina-1].marcado?'':_vm.verificar_respuesta(_vm.pagina-1,'B')}}},[_vm._v(_vm._s(_vm.preguntas[_vm.pagina-1].pregunta_respuesta2))]),_c('v-col',{class:[
                    (_vm.preguntas[_vm.pagina-1].pregunta_clave == _vm.preguntas[_vm.pagina-1].pregunta_respuesta3[0] && _vm.ver_respuestas)||_vm.preguntas[_vm.pagina-1].correcto == _vm.preguntas[_vm.pagina-1].pregunta_respuesta3[0]?'my-1 rounded-pill green lighten-1':'my-1 rounded-pill',
                    _vm.preguntas[_vm.pagina-1].marcado == _vm.preguntas[_vm.pagina-1].pregunta_respuesta3[0]?'my-1 rounded-pill '+_vm.preguntas[_vm.pagina-1].marcado_color:'my-1 rounded-pill '],attrs:{"cols":"12","disabled":"","rounded":""},on:{"click":function($event){_vm.preguntas[_vm.pagina-1].marcado?'':_vm.verificar_respuesta(_vm.pagina-1,'C')}}},[_vm._v(_vm._s(_vm.preguntas[_vm.pagina-1].pregunta_respuesta3))]),_c('v-col',{class:[
                    (_vm.preguntas[_vm.pagina-1].pregunta_clave == _vm.preguntas[_vm.pagina-1].pregunta_respuesta4[0] && _vm.ver_respuestas)||_vm.preguntas[_vm.pagina-1].correcto == _vm.preguntas[_vm.pagina-1].pregunta_respuesta4[0]?'my-1 rounded-pill green lighten-1':'my-1 rounded-pill',
                    _vm.preguntas[_vm.pagina-1].marcado == _vm.preguntas[_vm.pagina-1].pregunta_respuesta4[0]?'my-1 rounded-pill '+_vm.preguntas[_vm.pagina-1].marcado_color:'my-1 rounded-pill '],attrs:{"cols":"12","disabled":"","rounded":""},on:{"click":function($event){_vm.preguntas[_vm.pagina-1].marcado?'':_vm.verificar_respuesta(_vm.pagina-1,'D')}}},[_vm._v(_vm._s(_vm.preguntas[_vm.pagina-1].pregunta_respuesta4))])],1)],1)],1):_vm._e()],1),_c('v-col',{staticClass:"px-3",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center mx-5"},[_c('v-pagination',{attrs:{"length":200,"circle":""},model:{value:(_vm.pagina),callback:function ($$v) {_vm.pagina=$$v},expression:"pagina"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }